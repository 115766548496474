import React, { Component } from "react";
import {
  Segment,
  Header,
  Grid,
  Image,
  List,
  Container,
  Table,
  Button,
  Icon,
  Message,
  Divider
} from "semantic-ui-react";
import "./App.css";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Segment
          inverted
          textAlign="center"
          style={{ padding: "1em 0em 4em" }}
          vertical
          className="primary-image-area"
        >
          <Image
            src="/just_play_flyer_logo.png"
            centered
            className="logo-lockup"
          />
        </Segment>
        <Segment vertical>
          <Grid
            container
            stackable
            verticalAlign="top"
            className="layout-main-grid"
          >
            <Grid.Row>
              <Grid.Column width={8}>
                <Header as="h2">Where and when</Header>
                <Table>
                  <Table.Row>
                    <Table.Cell>Date</Table.Cell>
                    <Table.Cell>Saturday 16th February 2019</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Time</Table.Cell>
                    <Table.Cell>
                      Lessons will run in 30 minute slots from 10am to 4pm
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Location</Table.Cell>
                    <Table.Cell>
                      Music stores across the UK (see below)
                    </Table.Cell>
                  </Table.Row>
                </Table>

                <Header as="h2">Event details</Header>
                <p>
                  Children aged 5 to 16 can turn up to one of the music stores
                  below and learn to play a Foo Fighters song in just 30
                  minutes. No need to book a place, just turn up on the day and
                  register with a Rocksteady member of staff.
                </p>
                <p>
                  Rocksteady Music School are experts at getting any child
                  playing real music in their own bands within 10 minutes and
                  will provide all the instruments and teachers.
                </p>
                <p>
                  The event is open to all abilities so whether you already play
                  or have never touched an instrument before you have the chance
                  to become an instant rock star and learn and perform a song
                  from one of the greatest rock bands in history.
                </p>

                <Header as="h2">Locations</Header>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>City</Table.HeaderCell>
                      <Table.HeaderCell>Store</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Row>
                    <Table.Cell>Birmingham</Table.Cell>
                    <Table.Cell>
                      <a
                        href="https://www.google.co.uk/maps/place/PMT+Birmingham/@52.4833622,-1.88156,17z/data=!3m1!4b1!4m5!3m4!1s0x4870bb7d6b741daf:0x68b3ab5d317065ca!8m2!3d52.4833622!4d-1.8793713"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        PMT, 136 Lawley Middleway, Birmingham, B4 7XX
                      </a>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Bristol</Table.Cell>
                    <Table.Cell>
                      <a
                        href="https://www.google.co.uk/maps/place/PMT+Bristol/@51.4572499,-2.5961477,17z/data=!3m1!4b1!4m5!3m4!1s0x48718e764b5b7bb7:0x1ff56d4b0bffc955!8m2!3d51.4572499!4d-2.593959"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        PMT,5 Rupert Street, Bristol, BS1 2PY
                      </a>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Guildford</Table.Cell>
                    <Table.Cell>
                      <a
                        href="https://www.google.com/maps/place/The+Boileroom+Music+and+Community+Arts+Venue/@51.2399155,-0.5730271,15z/data=!4m2!3m1!1s0x0:0x273661975367dfbf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        The Boileroom Music and Community Arts Venue, 13 Stoke
                        Fields, Guildford, GU1 4LS
                      </a>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Kingston</Table.Cell>
                    <Table.Cell>
                      <a
                        href="https://www.google.com/maps/place/Banquet+Records/@51.4091518,-0.3032643,15z/data=!4m2!3m1!1s0x0:0xc770cd72ccfa1cae?ved=2ahUKEwj2hq-5hJjgAhViTxUIHWuGDZ4Q_BIwEnoECAYQCA"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Banquet Records, 52 Eden St, Kingston upon Thames KT1
                        1EE
                      </a>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Leeds</Table.Cell>
                    <Table.Cell>
                      <a
                        href="https://www.google.co.uk/maps/place/PMT+Leeds/@53.79855,-1.5662557,17z/data=!3m1!4b1!4m5!3m4!1s0x48795ea4b9851587:0xda4ac673408c2907!8m2!3d53.79855!4d-1.564067"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        PMT, Citygate, 9 Kirkstall Road, Leeds, LS3 1LH
                      </a>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Manchester</Table.Cell>
                    <Table.Cell>
                      <a
                        href="https://www.google.co.uk/maps/place/PMT+Manchester/@53.4780563,-2.2753965,17z/data=!3m1!4b1!4m5!3m4!1s0x487bae226de34995:0xa96836e1b7b3732a!8m2!3d53.4780563!4d-2.2732078"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        PMT, Red Rose Centre, Regent Road, Manchester, M5 3GR
                      </a>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Northampton</Table.Cell>
                    <Table.Cell>
                      <a
                        href="https://www.google.co.uk/maps/place/PMT+Northampton/@52.2344724,-0.8998939,17z/data=!3m1!4b1!4m5!3m4!1s0x48770ede15b8544b:0xac08805390d8cdd6!8m2!3d52.2344724!4d-0.8977052"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        PMT, 86 Bridge Street, Northampton, NN1 1PD
                      </a>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Nottingham</Table.Cell>
                    <Table.Cell>
                      <a
                        href="https://www.google.co.uk/maps/place/PMT+Nottingham/@52.955585,-1.1442337,17z/data=!3m1!4b1!4m5!3m4!1s0x4879c17de96073c5:0xcbad8475aa5af221!8m2!3d52.955585!4d-1.142045"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        PMT, 2 Marco Island, Huntingdon Street, Nottingham, NG1
                        1AR
                      </a>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Portsmouth</Table.Cell>
                    <Table.Cell>
                      <a
                        href="https://www.google.co.uk/maps/place/PMT+Portsmouth/@50.8437407,-1.0382294,17z/data=!3m1!4b1!4m5!3m4!1s0x4874434c7f13ed09:0x5ae6847c11c9f6c1!8m2!3d50.8437407!4d-1.0360407"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        PMT, Unit 12 Fitzherbert Spur, Portsmouth, PO6 1TT
                      </a>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Romford</Table.Cell>
                    <Table.Cell>
                      <a
                        href="https://www.google.co.uk/maps/place/PMT+Romford/@51.586738,0.1714933,17z/data=!3m1!4b1!4m5!3m4!1s0x47d8a4cbf45d85c7:0xaaf9df173d7d7b45!8m2!3d51.586738!4d0.173682"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        PMT, 10-14 Eastern Avenue East, Romford, Essex, RM1 4DR
                      </a>
                    </Table.Cell>
                  </Table.Row>
                </Table>
              </Grid.Column>

              <Grid.Column floated="right" width={6}>
                <Header as="h3">About Rocksteady</Header>
                <Image src="/rs_drummer.jpg" centered rounded />

                <p>
                  <br />
                  Rocksteady Music School are on a mission to empower as many
                  children as possible through rock and pop music. Each year
                  over 250,000 children across the UK benefit from Rocksteady
                  lessons, bursaries, free assemblies and free workshops.
                </p>

                <p>
                  The Rocksteady way is a unique and proven teaching methodology
                  developed by experts over ten years and enables children as
                  young as four years old to immediately play real music in
                  their own rock and pop bands even if they have never touched
                  an instrument before.
                </p>

                <p>
                  Children get the chance to perform the songs they love, whilst
                  learning valuable life skills along the way, building
                  confidence, developing resilience, improving social skills,
                  and enhancing their wellbeing.
                </p>

                <p>
                  Through the Rocksteady foundation we bring the benefits of
                  music to children who would not normally have access to
                  lessons. In 2018 we held 87 free music workshops, in SEN
                  schools, hospices, domestic abuse refuges and charities.
                </p>

                <p>
                  If you would like to know more about lessons or Rocksteady’s
                  foundation work please email{" "}
                  <a href="mailto: justplay@rocksteadymusicschool.com">
                    justplay@rocksteadymusicschool.com
                  </a>{" "}
                  or visit{" "}
                  <a href="https://www.rocksteadymusicschool.com">
                    www.rocksteadymusicschool.com
                  </a>
                </p>

                <Divider section />

                <Message>
                  <Header as="h3">"Small print"</Header>
                  <ul>
                    <li>
                      There is no charge for taking part in the event, no
                      experience necessary and all instruments will be provided.
                    </li>
                    <li>
                      We will include as many children as time allows and may
                      need to offer lessons on a first come first served basis
                      depending on demand.
                    </li>
                    <li>
                      All children need to be accompanied by an adult and have
                      permission from their parent or guardian to participate.
                    </li>
                  </ul>
                </Message>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Image
            src="/just_play_logo_lockup.png"
            centered
            className="footer-logo-lockup"
          />
        </Segment>

        <Segment inverted vertical style={{ padding: "5em 0em" }}>
          <Container>
            <Grid divided inverted stackable>
              <Grid.Row>
                <Grid.Column width={3}>
                  <Header inverted as="h4" content="Links" />
                  <List link inverted>
                    <List.Item
                      as="a"
                      href="https://www.rocksteadymusicschool.com/"
                    >
                      Rocksteady Music School
                    </List.Item>
                    <List.Item as="a" href="https://play.roswellfilms.com/">
                      Play by Dave Grohl
                    </List.Item>
                    <List.Item as="a" href="https://foofighters.com/">
                      Foo Fighters
                    </List.Item>
                    <List.Item as="a" href="https://www.columbia.co.uk/">
                      Columbia Records
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header inverted as="h4" content="Website" />
                  <List link inverted>
                    <List.Item
                      as="a"
                      href="https://www.rocksteadymusicschool.com/privacy-policy/"
                    >
                      Privacy Policy
                    </List.Item>
                    <List.Item
                      as="a"
                      href="https://www.rocksteadymusicschool.com/privacy-notices/"
                    >
                      Privacy Notices
                    </List.Item>
                    <List.Item
                      as="a"
                      href="https://www.rocksteadymusicschool.com/cookie-policy/"
                    >
                      Cookie Policy
                    </List.Item>
                    <List.Item
                      as="a"
                      href="https://www.rocksteadymusicschool.com/terms-conditions/"
                    >
                      Terms &amp; Conditions
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Header as="h4" inverted>
                    Follow us
                  </Header>
                  <Button
                    as="a"
                    color="facebook"
                    href="https://www.facebook.com/rocksteadymusicschool"
                    icon
                  >
                    <Icon name="facebook" /> Facebook
                  </Button>{" "}
                  <Button
                    as="a"
                    color="twitter"
                    href="https://twitter.com/rsmusicschool"
                    icon
                  >
                    <Icon name="twitter" /> Twitter
                  </Button>{" "}
                  <Button
                    as="a"
                    color="instagram"
                    href="https://www.instagram.com/rocksteadymusicschool/"
                    icon
                  >
                    <Icon name="instagram" /> Instagram
                  </Button>
                  <Divider basic />
                  <List.Item
                    as="a"
                    href="/terms.pdf"
                    target="_blank"
                    style={{ color: "#FFF" }}
                  >
                    Prize Draw Terms &amp; Conditions
                  </List.Item>
                  <Divider basic />
                  <p>
                    Website &copy; 2019 <em>Rocksteady Music School</em>,
                    Liphook, Hampshire. All Rights Reserved.{" "}
                    <em>Play with Dave Grohl</em>, <em>Foo Fighters</em> and{" "}
                    <em>Columbia Records</em> logos used with permission.
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      </div>
    );
  }
}

export default App;
